import Vue from "vue";
import axios from "axios";
import VueResource from "vue-resource";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/base";
import "./plugins/chartist";
import "./plugins/vee-validate";
//import "./plugins/vue-world-map";
import "./style/variables.css";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import { VueMaskDirective } from "v-mask";
import FlashMessage from "@smartweb/vue-flash-message";
import money from "v-money";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import vuescroll from "vuescroll";
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faUserSecret,
  faChartColumn,
  faCircleCheck,
  faCircleXmark,
  faXmark,
  faCircleInfo,
  faArrowLeft,
  faCircle,
  faChevronUp,
  faChevronDown,
  faCheck,
  faPencil,
  faTriangleExclamation,
  faGlobeAmericas,
  faCopy,
  faEye,
  faFileInvoice,
  faDownload,
  faHourglassStart,
  faCirclePlay,
  faDatabase,
  faChevronRight,
  faUserCheck,
  faArrowRight,
  faPlus,
  faInfo,
  faUserCircle,
  faSliders,
} from "@fortawesome/free-solid-svg-icons";
import VueGtag from "vue-gtag";
import mixpanel from "mixpanel-browser";

mixpanel.init(process.env.VUE_APP_MIX_PANEL_TOKEN, {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});

library.add(
  faUserSecret,
  faChartColumn,
  faCircleCheck,
  faCircleXmark,
  faXmark,
  faCircleInfo,
  faArrowLeft,
  faCircle,
  faChevronUp,
  faChevronDown,
  faCheck,
  faPencil,
  faTriangleExclamation,
  faGlobeAmericas,
  faCopy,
  faEye,
  faFileInvoice,
  faDownload,
  faHourglassStart,
  faCirclePlay,
  faDatabase,
  faChevronRight,
  faUserCheck,
  faArrowRight,
  faPlus,
  faInfo,
  faCircleInfo,
  faUserCircle,
  faSliders
);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.config.productionTip = false;
Vue.config.performance = true;
//Vue.use(VueQuillEditor /* { default global options } */);

Vue.directive("mask", VueMaskDirective);
Vue.config.productionTip = false;
Vue.use(FlashMessage);
Vue.use(money, { precision: 2 });
Vue.use(Cropper);
Vue.use(vuescroll, {
  ops: {
    // The global config
  },
});
const options = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  hideProgressBar: false,
  icon: true,
};

Vue.use(Toast, options);

Vue.$globalEvent = new Vue();

Vue.use(VueResource);
Vue.use(VueLodash, { name: "custom", lodash: lodash });

const ignoreWarnMessage =
  "The .native modifier for v-on is only valid on components but it was used on <div>.";
Vue.config.warnHandler = function (msg, vm, trace) {
  // trace is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
};

Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_GA },
    enabled: process.env.VUE_APP_GA_ENABLED === "TRUE",
  },
  router
);

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN || "",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        "dxainvest-preprod.azurewebsites.net",
        "app.dxainvest.com",
      ],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  mounted() {
    Vue.$globalEvent.$on("httpError", (error) => {
      if (error) {
        if (error.status) {
          if (error.showException && error.status === 401) {
            if (error.body.message) {
              this.$toast.error(this.$t("unauthorized"));
            } else {
              this.$toast.error(this.$t("unauthorized"));
            }
            router.push({ name: "LoginClient" });
          } else if (error.showException && error.status === 403) {
            if (error.body.message) {
              this.$toast.error(this.$t("unauthorized"));
            } else {
              this.$toast.error(this.$t("unauthorized"));
            }
            router.push({ name: "LoginClient" });
          } else if (
            error.showException &&
            error.status === 412 &&
            error.body &&
            error.body.detail &&
            error.body.detail.toLowerCase().indexOf("expired") > -1
          ) {
            this.$toast.error(this.$t("an_error_ocurred"));
            router.push({ name: "LoginClient" });
          } else if (error.showException && error.status >= 500) {
            this.$toast.error(this.$t("an_error_ocurred"));
          }
        } else {
          if (error.status === 0) {
            this.$toast.error(this.$t("an_error_ocurred"));
            return;
          } else {
            this.$toast.error(this.$t("an_error_ocurred"));
          }
        }
      }
    });
  },
  render: (h) => h(App),
}).$mount("#app");
